import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import LContainer from "@/layout/LContainer.vue";
import { check_login } from "@/assets/js/api/userServices";
import store from "../store/index";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/index",
    name: "index",
    component: LContainer,
    children: [
      {
        path: "/index",
        name: "index",
        component: () => import("@/views/L/LIndex.vue"),
        meta: {
          title: "index",
        },
      },
      {
        path: "/branding",
        name: "branding",
        component: () => import("@/views/L/LBranding.vue"),
        meta: {
          title: "branding",
        },
      },
      {
        path: "/graphic",
        name: "graphic",
        component: () => import("@/views/L/LGraphic.vue"),
        meta: {
          title: "graphic",
        },
      },
      {
        path: "/typesetting",
        name: "typesetting",
        component: () => import("@/views/L/LTypesetting.vue"),
        meta: {
          title: "typesetting",
        },
      },
      // {
      //   path: "/project",
      //   name: "project",
      //   component: () => import("@/views/L/LProject.vue"),
      //   meta: {
      //     title: "新闻",
      //   },
      // },
      {
        path: "/contact",
        name: "contact",
        component: () => import("@/views/L/LContact.vue"),
        meta: {
          title: "contact",
        },
      },
      {
        path: "/general",
        component: () => import("@/views/L/general.vue"),
        meta: {
          title: "dynamicMenu",
        },
      }
    ],
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "Login" */ "@/views/Login.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});
router.beforeEach(function (to, from, next) {
  const width = window.innerWidth;
  const isMobile = /Mobi|Android/i.test(navigator.userAgent);
  if ((!isMobile && width >= 768) || to.name == "index") {
    // 只有没登录才校验
    if (store.state.user_info.user_id === 0) {
      check_login().then((res) => {
        store.state.user_info = res;
      });
    }

    window.scrollTo(0, 0);
    next();
  } else {
    // 当前如果是移动端，则跳转到首页
    // 并且需要重置首页的动画效果。
    sessionStorage.setItem("closeAnimation", "0");
    next("/index");
  }
});
export default router;
