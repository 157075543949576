import { httpService } from "@/assets/js/httpServices";
import { LoginVM, NewsListVM } from "@/assets/js/api/userServices.d";

export function login(data: LoginVM) {
  return httpService.post("/v1/home/login", data);
}
export function check_login() {
  return httpService.post("/v1/home/check_login");
}
export function contact_info() {
  return httpService.post("/v1/contact/info");
}
export function about_info() {
  return httpService.post("/v1/about/info");
}
export function about_add(data: any) {
  return httpService.post("/v1/about/about_add", data);
}

export function branding_list(page = 0, limit = 20) {
  return httpService.post("/v1/branding/list", {
    page: page,
    limit: limit,
  });
}

export function project_list(page = 0, limit = 20) {
  return httpService.post("/v1/project/list", {
    page: page,
    limit: limit,
  });
}

export function typesetting_list(page = 0, limit = 20) {
  return httpService.post("/v1/typesetting/list", {
    page: page,
    limit: limit,
  });
}

export function graphic_list(page = 0, limit = 20) {
  return httpService.post("/v1/graphic/list", {
    page: page,
    limit: limit,
  });
}
export function article_list(param: string, page = 0, limit = 20) {
  return httpService.post("/v1/article/list", {
    page: page,
    limit: limit,
    param: param,
  });
}

export function branding_del(id: number) {
  return httpService.post("/v1/branding/branding_del", {
    branding_id: id,
  });
}
export function typesetting_del(id: number) {
  return httpService.post("/v1/typesetting/typesetting_del", {
    typesetting_id: id,
  });
}
export function project_del(id: number) {
  return httpService.post("/v1/project/project_del", {
    project_id: id,
  });
}
export function graphic_del(id: number) {
  return httpService.post("/v1/graphic/graphic_del", {
    graphic_id: id,
  });
}
export function contact_del(id: number) {
  return httpService.post("/v1/contact/contact_del", {
    contact_id: id,
  });
}
export function article_del(id: number, param: string) {
  return httpService.post("/v1/article/article_del", {
    article_id: id,
    param: param,
  });
}

export function branding_add(data: any) {
  return httpService.post("/v1/branding/branding_add", data);
}
export function typesetting_add(data: any) {
  return httpService.post("/v1/typesetting/typesetting_add", data);
}
export function project_add(data: any) {
  return httpService.post("/v1/project/project_add", data);
}
export function graphic_add(data: any) {
  return httpService.post("/v1/graphic/graphic_add", data);
}
export function contact_add(data: any) {
  return httpService.post("/v1/contact/contact_add", data);
}
export function article_add(data: any) {
  return httpService.post("/v1/article/article_add", data);
}

export function branding_sort(list: any) {
  return httpService.post("/v1/branding/edit_sort", {
    list: list,
  });
}
export function typesetting_sort(list: any) {
  return httpService.post("/v1/typesetting/edit_sort", {
    list: list,
  });
}
export function project_sort(list: any) {
  return httpService.post("/v1/project/edit_sort", {
    list: list,
  });
}
export function graphic_sort(list: any) {
  return httpService.post("/v1/graphic/edit_sort", {
    list: list,
  });
}
export function article_sort(list: any, param: string) {
  return httpService.post("/v1/article/edit_sort", {
    list: list,
    param: param,
  });
}

export function graphic_fabu(graphic_id: any) {
  return httpService.post("/v1/graphic/fabu", {
    graphic_id: graphic_id,
  });
}
export function typesetting_fabu(typesetting_id: any) {
  return httpService.post("/v1/typesetting/fabu", {
    typesetting_id: typesetting_id,
  });
}
export function branding_fabu(branding_id: any) {
  return httpService.post("/v1/branding/fabu", {
    branding_id: branding_id,
  });
}
export function project_fabu(project_id: any) {
  return httpService.post("/v1/project/fabu", {
    project_id: project_id,
  });
}
export function article_fabu(article_id: any, param: string) {
  return httpService.post("/v1/article/fabu", {
    article_id: article_id,
    param: param,
  });
}

export function all_img() {
  return httpService.post("/v1/contact/all_img");
}
export function save_img(list: any) {
  return httpService.post("/v1/contact/save_img", {
    list: list,
  });
}
export function menu_add(data: any) {
  return httpService.post("/v1/menu/menu_add", data);
}
export function menu_list() {
  return httpService.post("/v1/menu/list");
}
export function menu_del(menu_id: any) {
  return httpService.post("/v1/menu/del", {
    menu_id: menu_id,
  });
}
